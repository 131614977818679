import { Component } from '@angular/core';
import { TopLevelMenuComponent } from '@cumlaude/shared-components-menu';

@Component({
	selector: 'app-admin-header',
	templateUrl: './admin-header.component.html',
	styleUrls: ['./admin-header.component.scss'],
	standalone: true,
	imports: [TopLevelMenuComponent],
})
export class AdminHeaderComponent {}
